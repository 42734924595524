import React from "react"
import mastercard from "@images/companies/mastercard.svg"
import smartcare from "@images/companies/smartcare.svg"
import avenu from "@images/companies/avenu.svg"
import quativa from "@images/companies/quativa.svg"

export default function Clients() {
  return (
    <div>
      <div className="grid grid-cols-2 gap-0.5 md:grid-cols-2 lg:mt-0">
        <div className="col-span-1 flex py-8 px-8">
          <img
            className="max-h-12"
            src={mastercard}
            alt="Mastercard"
          />
        </div>
        <div className="col-span-1 flex py-8 px-8">
          <img 
            className="max-h-12" 
            src={smartcare} 
            alt="Smartcare" />
        </div>
        <div className="col-span-1 flex py-8 px-8">
          <img 
              className="max-h-12" 
              src={avenu} 
              alt="Avenu" />
        </div>
        <div className="col-span-1 flex py-8 px-8">
        <img 
            className="max-h-12" 
            src={quativa} 
            alt="Quativa" />
        </div>
      </div>

      <div className="text-center text-sm text-neutral-300">and many more...</div>
    </div>
  )
}