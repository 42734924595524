import * as React from "react"
import Header from "@components/header/header"
import ButtonWithBall from "@components/button-with-ball/button-with-ball"
import Clients from "@components/clients/clients"
import Services from "@components/services/services"
import RecentProjects from "@components/recent-projects/recent-projects"
import { Helmet } from "react-helmet"

const IndexPage = () => {
  return (
    <main>
      <Helmet>
        <title>Molcode</title>
        <meta name="description" content="Where your ideas turn into products." />
        <body className="relative scroll-smooth bg-black" />
      </Helmet>

      <div class="background-video-container">
        <video autoplay="true" muted="true" loop="true" playsinline="true" className="background-video" id="trident">
            <source src={'/videos/trident.webm'} type="video/webm" />
            <source src={'/videos/trident.mp4'} type="video/mp4" />
        </video>
        <div class="background-video-fade"></div>
      </div>

      <Header />

      <div className="sections">
        <section className="hero">
          <div class="line text-white"></div>

          <div className="container">
            <h1 className="text-6xl lg:text-9xl font-bold text-white">Molcode</h1>
            <div className="text-base lg:text-3xl text-neutral-400">Where your ideas turn into products</div>
          </div>
        </section>

        <section className="bg-white relative overflow-hidden" id="services">
          <div className="absolute right-0 top-0 w-1/3 aspect-square bg-neutral-100 rounded-full -translate-y-40 translate-x-40"></div>

          <div className="container">
            <div className="grid grid-rows-2 lg:grid-rows-none grid-cols-none lg:grid-cols-2 space-x-0 space-y-2 lg:space-y-0 lg:space-x-16">
              <div>
                <h2 className="font-medium">our services</h2>
                <h3 class="mt-2 lg:mt-4 text-xl lg:text-4xl font-medium">
                  We take care of everything you need to put your product on the market.
                </h3>
                <div class="mt-4 lg:mt-8">
                From native mobile applications to presentation websites, complete back-end solutions, decentralized applications, UI/UX design, motion design, and 3D modeling, we've got you covered.
                </div>
              </div>

              <Services />
            </div>
          </div>
        </section>

        <section className="bg-black text-white relative overflow-hidden" id="clients">
          <div className="container">
            <div className="flex flex-col lg:flex-row space-y-2 lg:space-y-0 lg:space-x-16">
              <div>
                <h2 className="font-medium">our clients</h2>
                <h3 class="mt-2 lg:mt-4 text-xl lg:text-4xl font-medium">
                  Our people have worked with some of the greatest companies out there.
                </h3>
                <div class="mt-4 lg:mt-8">
                  <ButtonWithBall 
                    href="mailto:contact@molcode.software" 
                    title="Molcode: Contact us" 
                    className="inline-block bg-white py-2 lg:py-3 px-4 lg:px-6 rounded-full text-base font-medium text-black hover:bg-white" 
                    tintColor="bg-gray-900">Become our client</ButtonWithBall>
                </div>
              </div>

              <Clients />
            </div>
          </div>
        </section>
      </div>

      <section className="bg-white relative overflow-hidden" id="projects">
        <div className="container">
          <div>
            <h2 className="font-medium">projects</h2>
            <h3 class="mt-2 lg:mt-4 text-xl lg:text-4xl font-medium">
              Here are some of our most recent projects <br />
              that we worked on.
            </h3>
          </div>

          <RecentProjects />
        </div>
      </section>

      <footer id="contact" class="relative bg-black text-white text-center pb-0 lg:pb-24">
        <div className="absolute right-1/2 bottom-0 w-1/3 aspect-square bg-neutral-900 rounded-full translate-y-3/4 translate-x-1/2"></div>

        <div class="container relative">
          <div>
            <div>Say hi 👋</div>
            <div className="mt-4"><a href="mailto:contact@molcode.software?subject=Hi" class="text-2xl lg:text-4xl mb-8 md:mb-32 hover:text-neutral-300 font-bold">contact@molcode.software</a></div>
            <div className="mt-2"><a href="tel:+40751888620" class="text-lg lg:text-2xl mb-8 md:mb-32 hover:text-neutral-300 font-bold">+40 751 888 620</a></div>
          </div>

          <div className="mt-8 flex flex-row items-center justify-center space-x-6">
            <a href="https://facebook.com/Molcode" className="hover:text-neutral-300" target="_blank" rel="external nofollow noopener noreferrer">
              <svg fill="currentColor" viewBox="0 0 24 24" className="w-6 h-6">
                <path
                  fillRule="evenodd"
                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
            <a href="https://twitter.com/Molcode" className="hover:text-neutral-300" target="_blank" rel="external nofollow noopener noreferrer">
              <svg fill="currentColor" viewBox="0 0 24 24" className="w-6 h-6">
                <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
              </svg>
            </a>
          </div>

          <div class="copyright">© 2022 Molcode S.R.L.</div>
        </div>
      </footer>
    </main>
  )
}

export default IndexPage
