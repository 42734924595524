import React from "react"

const data = [
  {
    title: "Ad Control",
    url: "https://apps.apple.com/ro/app/ad-control-say-goodbye-to-ads/id1037290598",
    icon: "/projects/ad-control.png",
    icon2x: "/projects/ad-control@2x.png",
    domain: "iOS, macOS",
  },
  {
    title: "Block Tracking",
    url: "https://apps.apple.com/ro/app/block-tracking/id1037288979",
    icon: "/projects/block-tracking.png",
    icon2x: "/projects/block-tracking@2x.png",
    domain: "iOS, macOS",
  },
  {
    title: "TV Romania X",
    url: "https://apps.apple.com/ro/app/tv-romania-x/id1131232039",
    icon: "/projects/tv-romania-x.png",
    icon2x: "/projects/tv-romania-x@2x.png",
    domain: "iOS, tvOS, macOS",
  },
  {
    title: "EMMAR Imobiliare",
    url: "https://www.emmar-imobiliare.ro/",
    icon: "/projects/emmar.png",
    icon2x: "/projects/emmar@2x.png",
    domain: "Website",
  },
  {
    title: "Pensiunea Confort",
    url: "https://pensiunea-confort.ro/",
    icon: "/projects/pensiunea-confort.png",
    icon2x: "/projects/pensiunea-confort@2x.png",
    domain: "Website",
  },
  {
    title: "Cabinet de avocat „Negru Doina”",
    url: "https://avocat-negrudoina.ro/",
    icon: "/projects/negru-doina.png",
    icon2x: "/projects/negru-doina@2x.png",
    domain: "Website",
  },
  {
    title: "infofirme",
    url: "https://apps.apple.com/ro/app/infofirme-find-company-info/id1605350605",
    icon: "/projects/infofirme.png",
    icon2x: "/projects/infofirme@2x.png",
    domain: 'iOS, macOS',
  },
  {
    title: "Cassette for 8tracks Radio",
    url: "https://apps.apple.com/ro/app/cassette-for-8tracks-radio/id1180322622",
    icon: "/projects/cassette.png",
    icon2x: "/projects/cassette@2x.png",
    domain: 'tvOS',
  },
  {
    title: "Capital",
    url: "https://apps.apple.com/ro/app/capital-cryptocurrency-tracker/id1360625610?mt=12",
    icon: "/projects/capital.png",
    icon2x: "/projects/capital@2x.png",
    domain: 'macOS',
  },
]

export default function RecentProjects() {
  return (
    <div className="mt-8 grid grid-cols-2 md:grid-cols-3 gap-4">
      {data.map(project => (
        <div className="flex flex-col xl:flex-row items-center justify-center lg:justify-start space-x-0 space-y-8 lg:space-x-4 space-y-0">
          <a href={project.url} target="_blank" rel="external nofollow noopener noreferrer" title={project.title}>
            <img src={project.icon} srcSet={`${project.icon}, ${project.icon2x} 2x`} alt={project.title} className="border w-32 h-32 rounded-full" />
          </a>

          <div>
            <a href={project.url} className="block text-center xl:text-left font-medium" target="_blank" rel="external nofollow noopener noreferrer" title={project.title}>{project.title}</a>
            <div className="text-sm text-neutral-500 text-center xl:text-left">{project.domain}</div>
          </div>
        </div>  
      ))}
    </div>
  )
}