import React from "react"

const services = [
  {
    id: 1,
    title: "Custom Software Development",
  },
  {
    id: 2,
    title: "Product Development",
  },
  {
    id: 3,
    title: "Software Architecture",
  },
  {
    id: 4,
    title: "Quality Assurance",
  },
  {
    id: 5,
    title: "Project Management",
  },
  {
    id: 6,
    title: "Business Analysis",
  },
  {
    id: 7,
    title: "Maintenance and Support",
  }
]

export default function RecentProjects() {
  return (
    <div>
      {services.map((service, index) => (
        <div key={service.id} className="text-neutral-700 hover:text-neutral-900">
          <div className={`font-medium text-base lg:text-lg pb-2 ${index === 0 ? '' : 'pt-2'} ${index === services.length - 1 ? '' : 'border-b bottom-bottom'}`}>{service.title}</div>
        </div>
      ))}
    </div>
  )
}