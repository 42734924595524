import React, { Fragment } from "react"
import { Popover, Transition } from '@headlessui/react'
import Logo from "@components/logo/logo"
import ButtonWithBall from "@components/button-with-ball/button-with-ball"
import { MenuIcon, XIcon } from '@heroicons/react/outline'

const navigation = [

  { name: 'Services', href: '#services' },
  { name: 'Clients', href: '#clients' },
  { name: 'Projects', href: '#projects' },
]

export default function Header() {
  return (
    <Popover className="relative">
      <header className="header">
        <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
          <div className="w-full py-8 flex items-center justify-between">
            <a href="/" className="text-gray-200 hover:text-white focus:outline-none" title="Molcode: Homepage">
              <Logo className="w-12 h-auto fill-current mr-2 inline" />
              <span className="font-bold">Molcode</span>
            </a>

            <div className="-mr-2 -my-2 md:hidden">
              <Popover.Button className="rounded-full p-2 inline-flex items-center justify-center text-gray-200 hover:text-white focus:outline-none">
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>

            <Popover.Group as="nav" className="hidden md:flex space-x-10">
              <div className="flex items-center">
                <div className="hidden ml-10 space-x-8 md:block">
                  {navigation.map((link) => (
                    <a key={link.name} href={link.href} title={`Molcode: ` + link.name} className="text-base font-medium text-gray-200 hover:text-white">
                      {link.name}
                    </a>
                  ))}
                </div>

                <div className="ml-10 space-x-4">
                  <ButtonWithBall 
                    href="#contact" 
                    title="Molcode: Contact us" 
                    className="bg-white py-2 lg:py-3 px-4 lg:px-6 rounded-full text-base font-medium text-black hover:bg-white" 
                    tintColor="bg-gray-900">Let's talk</ButtonWithBall>
                </div>
              </div>
            </Popover.Group>
          </div>

          <Transition
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel focus className="absolute z-10 top-0 inset-x-0 p-3 transition transform origin-top-right md:hidden">
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                <div className="pt-4 pb-5 px-4">
                  <div className="flex items-center justify-between">
                    <a href="/" className="text-black hover:text-neutral-900 focus:outline-none" title="Molcode: Homepage">
                      <Logo className="w-12 h-auto fill-current mr-2 inline" />
                      <span className="font-bold">Molcode</span>
                    </a>
                    
                    <div className="-mr-2">
                      <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none">
                        <span className="sr-only">Close menu</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                </div>
                
                <div className="py-6 px-5 space-y-6">
                  <div className="grid grid-cols-1 gap-y-4 gap-x-8">
                      {navigation.map((link) => (
                        <a key={link.name} href={link.href} className="text-base font-medium text-black hover:text-neutral-900">
                          {link.name}
                        </a>
                      ))}

                      <ButtonWithBall 
                        href="#contact" 
                        title="Molcode: Contact us" 
                        className="text-center bg-black py-3 px-8 rounded-full text-base font-medium text-white hover:bg-neutral-900"
                        tintColor="bg-neutral-100">Let's talk</ButtonWithBall>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </nav>
      </header>
    </Popover>
  )
}