import React, { useState } from "react"
import { animated, easings, useSpring } from "react-spring";

export default function ButtonWithBall(props) {
  const [ shouldAnimate, setShouldAnimate ] = useState(false)

  const styles = useSpring({
    loop: { reverse: true },
    config: { 
      duration: 300,
      easing: easings.easeOutBack,
    },
    cancel: shouldAnimate ? false : true,
    reset: true,
    from: {
      translateY: -1
    },
    to: {
      translateY: 6
    },
    onProps: (props, spring) => {
      if (props.cancel) {
        spring.set(-1)
      }
    },
  })

  const onMouseEnter = () => {
    setShouldAnimate(true)
  }

  const onMouseLeave = () => {
    setShouldAnimate(false)
  }

  return (
    <a
      role="button"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...props}
    >
      <span>{props.children}</span>
      <animated.span className={`ml-2 inline-block rounded-full w-2 h-2 ${props.tintColor}`} style={styles}></animated.span>
    </a>
  )
}